import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import React from 'react';

import {Link as RLink, navigate} from '@reach/router';

import userConfig from '../../config';

import Layout from './layout';

import Article from '../components/Article';
import ArticleHeader from '../components/ArticleHeader';
import Button from '../components/Button';
import Card from '../components/Card';
import Container from '../components/Container';
import FeaturedImage from '../components/FeaturedImage';
import PageNav from '../components/PageNav';
import Share from '../components/Share';

import ClipBoardJS from 'clipboard';

import $ from 'jquery';

// could eventually make this into a functional component but like.... eh....

class BlogPostTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            headers: [],
            waypointsDisabled: false,
            headerIndex: 0
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.info('component did update', this.props.location);
        if (this.props.location && this.props.location.state && this.props.location.state.elmId) {
            if (!this.state.waypointsDisabled) {
                let _headerIndex = 0;
                if (this.state.headers.length > 0) {
                    this.state.headers.forEach((header, i) => {
                        if (header[1].includes(this.props.location.state.elmId)) {
                            _headerIndex = i;
                        }
                    })
                }
                // console.info('updating header index');
                this.updateHeaderIndex(_headerIndex);
            }
            // console.info(this.props.location.state.elmId);
            this.setNavElCurrent(this.props.location.state.elmId);
        }
    }

    setNavElCurrent(id) {
        const $t = $(document.getElementById(`nav-${encodeURIComponent(btoa(id))}`));
        const $wrap = $('.categories-bar');
        // console.info('setting to', $t);
        $("[data-is-current]").each(function () {
            $(this).attr('data-is-current', null);
        })

        // scrollable
        if ($wrap.get()[0].scrollHeight > $wrap.get()[0].clientHeight) {
            const elem_position = $t.get()[0].offsetTop;
            const wrapper_height = $wrap.height();
            const y = elem_position - wrapper_height / 2 + 90;
            $wrap.stop().animate({scrollTop: y}, 200);
        }

        $t.attr('data-is-current', "true");
    }

    componentDidMount() {
        const headersSelection = ["h1", "h2", "h3", "h4", "h5"].map(str => "article > div > " + str).join(", ");
        const $headers = $(headersSelection);

        let _usedStates = [];

        let _test = []; // TODO: add sub headers / links

        $headers.each(
            function () {
                let hyphenated = $(this).text().replace(/\s/g, '-').replace(/^[^a-z]+|[^\w:.-]+/gi, "").toLowerCase();
                if (hyphenated.length <= 1) {
                    hyphenated = "empty";
                }

                if (_usedStates.includes(hyphenated)) {
                    let count = 0;
                    hyphenated += ("-" + count)
                    while (_usedStates.includes(hyphenated)) {
                        let splt = hyphenated.split("-")
                        splt[splt.length - 1] = "" + ++count;
                        hyphenated = splt.join("-")
                    }
                }

                _usedStates.push(hyphenated)

                $(this).append(`<div id="${hyphenated}" class="navigation-anchor"></div>`);
                $(this).attr('data-elm-id', hyphenated);

                _test.push([$(this).text(), `${window.location.href.replace(/(\/)$|(\/?#.*)$/, '')}#${hyphenated}`, hyphenated])

                if ($(this).children('a') && $(this).children('a')[0] && $(this).children('a')[0].href) {
                    // is external link on header

                    // const childEl = $(this).children('a')[0];
                    // const dest = .href;
                    // $(this).wrap(`<a href="${dest}" rel="noreferrer noopener" target="_blank"></a>`)
                } else {
                    $(this).attr('data-clipboard-text', `${window.location.href.replace(/(\/)$|(\/?#.*)$/, '')}#${hyphenated}`);
                    $(this).append('<i class="fas fa-link"/>');
                }
            }
        );

        $headers.on('mouseover', function () {
            if ($(this).children('a') && $(this).children('a')[0] && $(this).children('a')[0].href) {
                //ignore external link headers
            } else {
                const $svg = $($(this).children('svg')[0]);
                $svg.attr('data-hovered', "true");
            }
        })
        $headers.on('mouseout', function () {
            const $svg = $($(this).children('svg')[0]);
            $svg.attr('data-hovered', null);
        })

        const that = this;

        $headers.each(function () {
            if (!window.Waypoint) {
                setTimeout(function () {
                    window.location.reload();
                }, 1000 * 5)
                return;
            }
            new window.Waypoint({
                element: $(this).get()[0],
                handler: function () {
                    const _tId = $(this.element).data('elm-id');
                    that.setNavElCurrent(_tId);
                },
                offset: $(window).height() * 0.47
            })
        })

        $headers.on('click', function () {
            if ($(this).children('a') && $(this).children('a')[0] && $(this).children('a')[0].href) {
                // navigate("" + $(this).children('a')[0].href, {}).then(r => {
                //
                // });
            } else {
                navigate($(this).data('clipboard-text'), {state: {elmId: $(this).data('elm-id')}}).then(r => {

                })
            }
        })

        new ClipBoardJS(headersSelection);

        // console.debug("Successfully mounted jquery listeners at ", Date.now());

        let _headerIndex = -1;

        // console.info('rendered!');
        // console.info(this.state.headers);

        // console.info('props location state', this.props.location.state);

        if (window.location.href.includes("#")) {
            const headers = _test;
            const _id = window.location.href.substr(window.location.href.lastIndexOf('#'))
            if (headers.length > 0) {
                headers.forEach((header, i) => {
                    if (header[1].includes(_id)) {
                        _headerIndex = i;
                    }
                })
            }
            // console.info('_headerIndex', _headerIndex);
            // console.info(headers[_headerIndex]);
        }

        // console.info("=========================================")
        // console.info('is jumping to specific point?', _headerIndex >= 0);

        const jumpingToSpecific = _headerIndex >= 0;

        if (jumpingToSpecific) {
            if (window.Waypoint && !this.state.waypointsDisabled) {
                this.updateHeaderIndex(_headerIndex);
                // console.info('disable all waypoints');
            }
        }

        this.setState({
            headers: _test
        }, this.checkOverflow);
    }

    updateHeaderIndex(index) {
        if (this.state.headerIndex !== index) {
            this.setState({headerIndex: index});
            if (!this.state.waypointsDisabled) {
                window.Waypoint.disableAll();
                this.setState({waypointsDisabled: true});
                setTimeout(function () {
                    // console.info('enable all waypoints');
                    window.Waypoint.enableAll();
                    this.setState({waypointsDisabled: false});
                }.bind(this), 500)
            }
        }
    }

    checkOverflow(){
        $('.movable-content > .category-wrap > a > .sub-links').each(function(){
            const _e = $(this)[0]
            if (_e.offsetWidth < _e.scrollWidth) {
                // showing ellipsis
                $(this).attr('title', $(this).text());
                $(this).closest('.category-wrap').attr('title', $(this).text());
            }else{
                $(this).removeAttr('title');
                $(this).closest('.category-wrap').removeAttr('title');
            }
        })
    }

    componentWillUnmount() {
        const headersSelection = ["h1", "h2", "h3", "h4", "h5"].map(str => "article > div > " + str).join(", ");
        const $headers = $(headersSelection);
        $headers.off('mouseover mouseout click');
        window.Waypoint.destroyAll();
    }

    navToLink(to, elmId, event) {
        navigate(to, {state: {elmId: elmId}}).then(r => {
        });
    }

    render() {
        const post = this.props.data.markdownRemark;

        const author = get(this.props, 'data.site.siteMetadata.author');
        const {previous, next} = this.props.pageContext;

        let url = '';
        if (typeof window !== `undefined`) {
            url = window.location.href;
        }

        // console.info('viewing', this.state.headerIndex);

        return (
            <Layout>
                <div className={"dropdown-indicator"} onClick={(evt) => {
                    $('.dropdown-indicator').toggleClass('show-indicator')
                }} role={"presentation"}>
                    <div className={"categories-bar show-progress"}>
                        <RLink to={"/"} tabIndex={-1}>
                            <span className={"same-line"}>
                                <div className={"title"}>TinoIoT</div>
                                <div className={"sub-title"}> AT CHS</div>
                            </span>
                        </RLink>
                        <div className={"categories-overflow-mask"}/>
                        <div className={"movable-content"}>
                            {this.state.headers && this.state.headers.map((cat, i) =>
                                <div className={"category-wrap"}
                                     data-is-current={this.state.headerIndex >= 0 ? (i === this.state.headerIndex ? "true" : null) : (i === 0 ? "true" : null)}
                                     key={cat[1]}
                                     onClick={this.navToLink.bind(this, cat[1], cat[2])} role="presentation"
                                     id={"nav-" + encodeURIComponent(btoa(cat[2]))}>
                                    <RLink to={cat[1]} state={{elmId: cat[2]}}>
                                        <div className={"sub-links"}>
                                            {cat[0]}
                                        </div>
                                    </RLink>
                                </div>
                            )}
                        </div>
                    </div>
                    <i className={"fas fa-angle-double-down"}/>
                </div>
                <Container>
                    <Helmet
                        title={`${post.frontmatter.title}`}
                        htmlAttributes={{lang: 'en'}}
                    >
                        {post.frontmatter?.description &&
                        <meta name="description" content={post.frontmatter.description}/>
                        }
                        <meta name="keywords"
                              content={
                                  ((post.frontmatter && post.frontmatter.keywords && Array.isArray(post.frontmatter?.keywords)) ?
                                          Array.from(new Set(post.frontmatter.keywords))
                                              .sort((a, b) => b.length - a.length)
                                              .slice(0, 8).join(", ") :
                                          Array.from(new Set(post.fields.keywords.concat(['iot', 'tinoiot'])))
                                              .filter(str => str.match(/^[a-zA-Z]+[0-9]*$/))
                                              .sort((a, b) => b.length - a.length)
                                              .slice(0, 8).join(",")
                                  )}/>
                        <meta name="author" content={`${author}`}/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

                        <meta property="og:type" content="website"/>
                        <meta property="og:url"
                              content={`https://tinoiot.com${post.fields.slug || ""}`}/>
                        <meta property="og:title" content={`TinoIoT | ${post.frontmatter.title}`}/>
                        <meta property="og:description"
                              content={`${post.snippet || "Join TinoIoT today to learn about IoT devices! We teach languages like Python, C/C++, Javascript. We also teach hardware and electric skills including CAD-ing, circuity, and more!"}`}/>
                        <meta property="og:image"
                              content={`https://tinoiot.com${post.frontmatter?.featuredImage?.childImageSharp?.fluid?.src || "/res/img/main.png"}`}/>

                        <meta property="twitter:card" content="summary_large_image"/>
                        <meta property="twitter:url" content={`https://tinoiot.com${post.fields.slug || ""}`}/>
                        <meta property="twitter:title" content={`TinoIoT | ${post.frontmatter.title}`}/>
                        <meta property="twitter:description"
                              content={`${post.snippet || "Join TinoIoT today to learn about IoT devices! We teach languages like Python, C/C++, Javascript. We also teach hardware and electric skills including CAD-ing, circuity, and more!"}`}/>
                        <meta property="twitter:image"
                              content={`https://tinoiot.com${post.frontmatter?.featuredImage?.childImageSharp?.fluid?.src || "/res/img/main.png"}`}/>

                        <script src="https://kit.fontawesome.com/913ecb688b.js" crossOrigin="anonymous"/>
                    </Helmet>
                    <Card>
                        <ArticleHeader>
                            {post.frontmatter.featuredImage &&
                            <FeaturedImage
                                fluid={{...post.frontmatter.featuredImage.childImageSharp.fluid, aspectRatio: 1.9}}
                            />
                            }
                            <h1>{post.frontmatter.title}</h1>
                            <p>{post.frontmatter.date}</p>
                            <p><b>By <i> {post.frontmatter.author || "TinoIoT Officers"}</i></b></p>
                            <span/>
                        </ArticleHeader>
                        <Article>
                            <div dangerouslySetInnerHTML={{__html: post.html}}/>
                        </Article>
                        {userConfig.showShareButtons && (
                            <Share url={url} title={post.frontmatter.title}/>
                        )}
                    </Card>

                    <PageNav>
                        {previous && (
                            <Button to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Button>
                        )}

                        {next && (
                            <Button to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Button>
                        )}
                    </PageNav>
                </Container>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
        siteMetadata {
        title
        author
    }
    }
        markdownRemark(fields: {slug: {eq: $slug}}) {
            id
            html
            snippet
            fields {
                keywords
                slug
            }
            frontmatter {
                title
                keywords
                description
                author
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 900) {
                            src
                            srcSet
                            aspectRatio
                            sizes
                            base64
                            srcWebp
                            srcSetWebp
                        }
                    }
                }
            }
        }
    }
    `;
