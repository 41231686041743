import styled from 'styled-components';
import userConfig from '../../../config';

const Article = styled.article`
  color: #434d5f;
  font-size: 16px;
   
  .yt-wrapper{
    margin: 25px 50px 15px;
    display: flex;
    background: rgb(230,230,230);
    border-radius: 2px;
    
    iframe{
        min-height: 240px;
        max-height: 240px;
        min-width: 420px;
        max-width: 420px;
    }
    
    span{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px 10px 22px;
        a {
            color: #3e465b;
            text-decoration: none;
            
            p {
                margin: 0;
                padding: 0;
                color: #3e465b;
            }
        }
    }
  }
    
  a {
    color: ${userConfig.primaryColor};
    font-weight: 700;
    text-decoration: none;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }
  }
  
  p > .gatsby-resp-image-wrapper {
    max-width: unset !important;
    margin-top: 30px;
  }
  
  h2 + p > .gatsby-resp-image-wrapper { 
    margin-top: 15px;
  }
  
  div > h1:hover, div > h2:hover, div > h3:hover, div > h4:hover, div > h5:hover{
    // cursor: pointer;
    // text-decoration: underline;
  }
  
  div > h1, div > h2, div > h3, div > h4, div > h5{
    position: relative;
  }

  div > h1 > .fa-link, div > h2 > .fa-link, div > h3 > .fa-link, div > h4 > .fa-link, div > h5 > .fa-link{
    position: absolute;
    left: 0;
    font-size: 1.4rem;
    top: 55%;
    transform: translateY(-50%);
    opacity: 0;
  }
  
  div > * > .fa-link[data-hovered]{
    opacity: 1;
    &:hover{
        cursor: pointer;
    }
  }

  h1 {
    color: #3e465b;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    margin: 25px 50px 15px 30px;
    padding-left: 20px;
  }

  h2 {
    color: #3e465b;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;
    margin: 25px 50px 15px 30px;
    padding-left: 20px;
  }

  h3 {
    color: #3e465b;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    margin: 25px 50px 15px 30px;
    padding-left: 20px;
  }

  h4 {
    color: #3e465b;
    font-weight: 700;
    line-height: 1.5;
    margin: 25px 50px 15px 30px;
    padding-left: 20px;
  }

  h5,
  h6 {
    color: #3e465b;
    font-weight: 700;
    line-height: 1.5;
    margin: 25px 50px 15px 30px;
    padding-left: 20px;
  }

  p {
    line-height: 1.7;
    margin: 15px 50px;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .unset-width{
    width: unset !important;
  }
  
  table {
    margin: 15px 50px;  
    width: calc(100% - 100px);
  }

  blockquote {
    border-left: 5px solid #e0e6ed;
    line-height: 1.7;
    margin: 15px 50px 15px 75px;
    padding: 8px 10px 8px 15px;

    p {
      margin: 0;
    }
  }

  ul,
  ol {
    line-height: 1.7;
    margin: 15px 0;
    padding: 0 50px 0 100px;

    p,
    ul,
    ol {
      margin: 0;
    }

    ul,
    ol {
      padding: 0 0 0 30px;
    }
  }

  img {
    margin: 15px 0;
  }
  
  @media only screen and (max-width: 870px) {
    p,table {
      margin: 10px 25px;
    }
    table{
        width: calc(100% - 50px);
    }
    h1,h2,h3,h4,h5,h6,.yt-wrapper{
      margin 15px 25px 10px;
      padding: 0;
    }
    
    .yt-wrapper{
        flex-direction: column;
        iframe{
            max-width: 100%;
            min-width: 100%;
            max-height: 200px;
            min-height: 200px;
        }
        span{
            justify-content: unset;
        }
    }
    
    blockquote{
        padding: 5px 5px 5px 10px;
        margin: 10px 10px 10px 25px;
    }
    ul, ol{
        padding: 0 25px 0 50px;
    }
  }
`;

export default Article;
