import styled from 'styled-components';

const PostHeader = styled.div`
  h1 {
    color: #3e465b;
    font-size: 35px;
    line-height: 1.5;
    margin: 0;
    padding: 0 30px;
    text-align: center;
  }

  p {
    color: #666d71;
    display: block;
    font-size: 1.6em;
    margin: 0;
    text-align: center;
  }

  span {
    background: #9c9da3;
    display: block;
    margin: 50px auto;
    height: 1px;
    width: 150px;
  }
  
  @media only screen and (max-width: 870px) {
    h1 {
      line-height: 1.1;
      margin-bottom: 5px;
    }
    
    span{
      margin: 25px auto;
    }
  }
`;

export default PostHeader;
