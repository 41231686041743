import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 75px;
  text-align: center;
  
  @media only screen and (max-width: 870px) {
    margin-top: 35px
  }
`;

export default Wrapper;